<template>
  <list-buttons
    :is-hovered="isHovered"
    :extra-actions="extraActions"
    :primary-actions="primaryActions"
    @fireAction="fire"
  />
</template>
<script>
import ListButtons from "@/components/ListButtons.vue";
import categoryServiceActions from "@/calendesk/mixins/categoryServiceActions";

export default {
  name: "CategoryListButtons",
  components: { ListButtons },
  mixins: [categoryServiceActions],
  props: {
    category: {
      type: Object,
      required: true,
    },
    isHovered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    extraActions() {
      return [
        {
          action: "edit",
          color: "light",
          icon: "$pencil",
          title: this.$trans("edit"),
        },
        {
          action: "delete",
          color: "error",
          icon: "$trash-default",
          title: this.$trans("remove"),
        },
      ];
    },
    primaryActions() {
      return [
        {
          action: "edit",
          color: "light",
          icon: "$pencil",
          title: this.$trans("edit"),
        },
      ];
    },
  },
  methods: {
    fire(action) {
      const cloned = this.$helpers.cloneObject(this.category);
      switch (action) {
        case "edit":
          this.handleEditCategory(cloned);
          break;
        case "delete":
          this.handleRemoveCategory(cloned);
          break;
      }
    },
  },
};
</script>
