<template>
  <div>
    <v-row
      style="min-height: 70px"
      @mouseover="isHovered = true"
      @mouseleave="isHovered = false"
    >
      <v-col
        cols="2"
        md="1"
        class="d-flex align-center justify-md-center pa-0 pl-4 pl-md-0"
      >
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon
              class="category-handle hidden-sm-and-down"
              size="18"
              v-on="on"
              >$drag</v-icon
            >
          </template>
          {{ $trans("move") }}
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              color="blue-light"
              fab
              x-small
              elevation="0"
              dark
              @click="toggleOpenCategory"
              v-on="on"
            >
              <v-icon color="blue">
                {{ serviceOptions.isOpen ? "$chevron-up" : "$chevron-down" }}
              </v-icon>
            </v-btn>
          </template>
          <span v-if="serviceOptions.isOpen">{{ $trans("collapse") }}</span>
          <span v-else>{{ $trans("expand") }}</span>
        </v-tooltip>
      </v-col>
      <v-col
        cols="7"
        md="6"
        class="d-flex flex-wrap align-center justify-start text-break"
      >
        <div class="font-weight-medium mr-2">{{ category.name }}</div>
        <v-chip x-small :color="isActive ? 'green-light' : 'red-light'">
          <span
            class="text-truncate"
            :class="{
              'green--text': isActive,
              'accent_light--text': !isActive,
            }"
            >{{ $trans("category_" + category.status + "_short") }}</span
          >
        </v-chip>
      </v-col>
      <v-col cols="0" md="2" class="d-none d-md-flex align-center">
        <small v-if="category.services" class="font-weight-medium"
          >{{ $trans("quantity") }}:&nbsp;{{ category.services.length }}</small
        >
      </v-col>
      <v-col cols="0" md="2" class="d-none d-md-flex align-center"> </v-col>
      <v-col cols="3" md="1" class="d-flex align-center justify-end table-row">
        <div class="row-list-buttons white rounded mr-1 mt-5">
          <category-list-buttons :is-hovered="isHovered" :category="category" />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-divider />
      </v-col>
    </v-row>
    <template v-if="serviceOptions.isOpen">
      <slot name="service-content" />
    </template>
    <template v-if="serviceOptions.isOpen">
      <slot name="service-buttons" />
    </template>
  </div>
</template>

<script>
import categoryServiceActions from "@/calendesk/mixins/categoryServiceActions";
import CategoryListButtons from "@/views/dashboard/pages/Offer/Categories/components/CategoryTable/CategoryListButtons.vue";

export default {
  name: "CategoryTableRow",
  components: { CategoryListButtons },
  mixins: [categoryServiceActions],
  props: {
    categoryItem: {
      type: Object,
      default: null,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHovered: false,
      serviceOptions: {
        isOpen: false,
      },
    };
  },
  computed: {
    category() {
      return this.categoryItem;
    },
    isActive() {
      return this.category && this.category.status === "active";
    },
  },
  watch: {
    isOpen(isOpen) {
      this.serviceOptions.isOpen = isOpen;
    },
  },
  created() {
    this.serviceOptions.isOpen = this.isOpen;
  },
  methods: {
    toggleOpenCategory() {
      this.serviceOptions.isOpen = !this.serviceOptions.isOpen;
      this.emitExpandStatus();
    },
    emitExpandStatus() {
      this.$emit("updateExpandStatus", {
        categoryId: this.category.id,
        isOpen: this.serviceOptions.isOpen,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.category-handle {
  position: absolute !important;
  left: 5px;
}
</style>
