<template>
  <v-row
    class="service-row"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <v-col
      cols="0"
      md="1"
      class="d-none d-md-flex align-center justify-center pa-0 ma-0"
    >
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-icon class="service-handle" size="18" v-on="on">$drag</v-icon>
        </template>
        {{ $trans("move") }}
      </v-tooltip>
      <div
        class="table_row-line-bg-wrapper"
        :class="{
          'table_row-last-item': isLastItem,
        }"
      >
        <div class="table_row-line-bg"></div>
        <img
          :src="require(`@/assets/circle.svg`)"
          height="16px"
          class="table_row-line-image"
        />
      </div>
    </v-col>
    <v-col cols="9" md="6">
      <service-card-details justify-left :service="service" />
      <div v-if="!$vuetify.breakpoint.mdAndUp">
        {{ service.duration | duration }} / {{ service.price | money }}
      </div>
      <div>
        <v-chip
          :color="$helpers.getStatusColor(service.status).color"
          :class="$helpers.getStatusColor(service.status).class"
          x-small
          class="my-2 mr-2"
        >
          {{ $trans("category_" + service.status + "_short") }}
        </v-chip>
        <v-chip
          v-if="service.types && service.types.length > 0"
          x-small
          class="my-2 mr-2"
        >
          {{ service.types.length }} {{ $trans("variants") }}
        </v-chip>
        <v-chip v-if="service.max_people > 1" x-small class="my-2">
          {{ $trans("service_max_people_short_info") }}:
          {{ service.max_people }}
        </v-chip>
      </div>
    </v-col>
    <v-col cols="0" md="2" class="d-none d-md-flex">
      {{ service.duration | duration }}
    </v-col>
    <v-col cols="0" md="2" class="d-none d-md-flex">
      {{ service.price | money }}
    </v-col>
    <v-col cols="3" md="1" class="d-flex align-center justify-end table-row">
      <div class="row-list-buttons white rounded mr-1 mt-2">
        <service-list-buttons :is-hovered="isHovered" :service="service" />
      </div>
    </v-col>
    <v-col cols="12" class="border-child ma-0 pa-0" />
  </v-row>
</template>
<script>
import ServiceListButtons from "@/views/dashboard/pages/Offer/Categories/components/CategoryTable/ServiceListButtons.vue";
import ServiceCardDetails from "@/lib/calendesk-js-library/components/service/ServiceCardDetails.vue";

export default {
  components: { ServiceCardDetails, ServiceListButtons },
  props: {
    isLastItem: {
      type: Boolean,
      default: false,
    },
    service: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isHovered: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.border-child {
  border-bottom: 1px solid lightgray;
}

.service-row {
  font-size: 0.875rem;
}

.service-handle {
  position: absolute !important;
  left: 12px;
}

@media (max-width: 1264px) {
  .service-handle {
    left: 10px;
  }
}
</style>
