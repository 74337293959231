var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticStyle:{"min-height":"70px"},on:{"mouseover":function($event){_vm.isHovered = true},"mouseleave":function($event){_vm.isHovered = false}}},[_c('v-col',{staticClass:"d-flex align-center justify-md-center pa-0 pl-4 pl-md-0",attrs:{"cols":"2","md":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"category-handle hidden-sm-and-down",attrs:{"size":"18"}},on),[_vm._v("$drag")])]}}])},[_vm._v(" "+_vm._s(_vm.$trans("move"))+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"blue-light","fab":"","x-small":"","elevation":"0","dark":""},on:{"click":_vm.toggleOpenCategory}},on),[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v(" "+_vm._s(_vm.serviceOptions.isOpen ? "$chevron-up" : "$chevron-down")+" ")])],1)]}}])},[(_vm.serviceOptions.isOpen)?_c('span',[_vm._v(_vm._s(_vm.$trans("collapse")))]):_c('span',[_vm._v(_vm._s(_vm.$trans("expand")))])])],1),_c('v-col',{staticClass:"d-flex flex-wrap align-center justify-start text-break",attrs:{"cols":"7","md":"6"}},[_c('div',{staticClass:"font-weight-medium mr-2"},[_vm._v(_vm._s(_vm.category.name))]),_c('v-chip',{attrs:{"x-small":"","color":_vm.isActive ? 'green-light' : 'red-light'}},[_c('span',{staticClass:"text-truncate",class:{
            'green--text': _vm.isActive,
            'accent_light--text': !_vm.isActive,
          }},[_vm._v(_vm._s(_vm.$trans("category_" + _vm.category.status + "_short")))])])],1),_c('v-col',{staticClass:"d-none d-md-flex align-center",attrs:{"cols":"0","md":"2"}},[(_vm.category.services)?_c('small',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.$trans("quantity"))+": "+_vm._s(_vm.category.services.length))]):_vm._e()]),_c('v-col',{staticClass:"d-none d-md-flex align-center",attrs:{"cols":"0","md":"2"}}),_c('v-col',{staticClass:"d-flex align-center justify-end table-row",attrs:{"cols":"3","md":"1"}},[_c('div',{staticClass:"row-list-buttons white rounded mr-1 mt-5"},[_c('category-list-buttons',{attrs:{"is-hovered":_vm.isHovered,"category":_vm.category}})],1)])],1),_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-divider')],1)],1),(_vm.serviceOptions.isOpen)?[_vm._t("service-content")]:_vm._e(),(_vm.serviceOptions.isOpen)?[_vm._t("service-buttons")]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }