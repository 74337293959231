<template>
  <v-container
    v-if="!isSending"
    id="categories"
    fluid
    tag="section"
    class="mb-16"
  >
    <div>
      <template v-if="categoriesExist">
        <v-row v-if="$vuetify.breakpoint.mdAndUp" class="mb-8">
          <v-col cols="12" class="d-flex align-center">
            <v-alert color="accent" class="ma-0">
              <template #prepend>
                <v-icon color="blue-light-2" class="mr-2">$info</v-icon>
              </template>
              {{ $trans("category_order_info") }}
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3">
            <v-select
              v-model="selectedFiltersStatuses"
              :items="statuses"
              :label="$trans('service_status')"
              deletable-chips
              hide-details="auto"
              multiple
              outlined
              small-chips
              clearable
              dense
              :menu-props="{ offsetY: true }"
            >
              <template #item="data">
                <div class="d-flex align-center pointer my-2">
                  <span class="text-break cd-v-select-line-height">{{
                    data.item.text
                  }}</span>
                </div>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="9">
            <v-text-field
              v-model="selectedFiltersSearch"
              :label="$trans('service_name')"
              :placeholder="$trans('autocomplete_hint')"
              append-icon="$magnify"
              hide-details
              outlined
              single-line
              clearable
              dense
            />
          </v-col>
          <v-col class="d-flex align-center justify-center" cols="12">
            <v-btn color="primary" outlined small @click="clearFilters">
              {{ $trans("clear_filters") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="text-subtitle-2 light--text">
          <v-col style="width: 2px">
            <v-progress-linear
              :active="isLoading"
              color="primary"
              indeterminate
            />
          </v-col>
        </v-row>
        <v-row
          v-if="categories && categories.length > 0 && totalServices <= 100"
        >
          <v-col
            cols="4"
            md="1"
            class="d-flex align-center justify-md-center pa-0 pl-4 pl-md-0 mb-4"
          >
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  color="blue-light"
                  fab
                  elevation="0"
                  dark
                  x-small
                  @click="
                    allCategoriesExpanded
                      ? collapseAllCategories()
                      : expandAllCategories()
                  "
                  v-on="on"
                >
                  <v-icon color="blue">
                    {{ allCategoriesExpanded ? "$fold" : "$unfold" }}
                  </v-icon>
                </v-btn>
              </template>
              <span v-if="allCategoriesExpanded">{{
                $trans("collapse_all")
              }}</span>
              <span v-else>{{ $trans("expand_all") }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <draggable
          tag="div"
          :group="{ name: 'category-group' }"
          :list="categories"
          handle=".category-handle"
          :move="checkMove"
          @start="dragging = true"
          @end="onEnd('categories', categories)"
        >
          <category-row
            v-for="category in categories"
            :key="'category_' + category.id"
            :loading="isLoading"
            :category-item="category"
            :is-open="categoriesExpandStatus[category.id] === 'e'"
            @loading="isLoading = $event"
            @updateExpandStatus="updateCategoryExpandStatus"
          >
            <template #service-content>
              <service-rows
                v-if="category.services && category.services.length > 0"
                :services="category.services"
                @loading="isLoading = $event"
              />
            </template>
            <template #service-buttons>
              <div class="my-4 d-flex justify-end full-width">
                <service-table-buttons :category="category" />
              </div>
            </template>
          </category-row>
        </draggable>
      </template>
      <template v-else>
        <no-data-info
          :description="$trans('no_services_info')"
          :button-text="$trans('add_service')"
          @click="pushToAddService"
        />
      </template>
    </div>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";
import { mapActions, mapGetters } from "vuex";
import CategoryRow from "./components/CategoryTable/CategoryRow.vue";
import ServiceRows from "./components/CategoryTable/ServiceRows.vue";
import ServiceTableButtons from "./components/CategoryTable/ServiceRowButtons.vue";
import categoryServiceActions from "@/calendesk/mixins/categoryServiceActions";
import NoDataInfo from "@/components/common/NoDataInfo.vue";
import statsActions from "@/calendesk/mixins/statsActions";
import { trans } from "@/lib/calendesk-js-library/prototypes/trans";
import { debounce } from "debounce";
import {
  getItem,
  setItem,
} from "@/lib/calendesk-js-library/tools/localStorageManager";
import cacheActions from "@/calendesk/mixins/cacheActions";

export default {
  name: "Categories",
  components: {
    NoDataInfo,
    CategoryRow,
    ServiceRows,
    ServiceTableButtons,
    draggable,
  },
  mixins: [categoryServiceActions, statsActions, cacheActions],
  data() {
    return {
      cacheId: "categories-services-list",
      statsMode: this.$helpers.statsMode.categories,
      isClearingFilters: false,
      expandStatusRestored: false,
      totalServices: 0,
      categoriesExpandStatus: {},
      allCategoriesExpanded: false,
      isLoading: false,
      selectedFiltersStatuses: [],
      selectedFiltersSearch: null,
      statuses: [
        {
          text: trans("active"),
          value: "active",
        },
        {
          text: trans("inactive"),
          value: "inactive",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      categoryWithServices: "category/categoryWithServices",
      cachedTableFilters: "cache/getTableFilters",
    }),
  },
  watch: {
    categoryWithServices() {
      this.loadData(true);
    },
    "$route.name": function () {
      this.shouldOpenAddCategoryConfirmDialog();
    },
  },
  mounted() {
    this.shouldOpenAddCategoryConfirmDialog();
  },
  created() {
    document.documentElement.scrollTop = 0;
    this.restoreFilters();
    this.loadData().finally(() => {
      this.restoreExpandStatus();

      this.$watch("selectedFiltersStatuses", this.loadDataDebounced);
      this.$watch("selectedFiltersSearch", this.loadDataDebounced);
    });
  },
  methods: {
    ...mapActions({
      searchForServices: "category/searchForServices",
      setCachedTableFilters: "cache/setTableFilters",
    }),
    setAllCategoriesExpanded() {
      let result = true;

      for (const index in this.categories) {
        if (
          !this.categoriesExpandStatus[this.categories[index].id] ||
          this.categoriesExpandStatus[this.categories[index].id] === "c"
        ) {
          result = false;
          break;
        }
      }

      this.allCategoriesExpanded = result;
    },
    shouldOpenAddCategoryConfirmDialog() {
      if (this.$route.name === "add_category") {
        this.handleAddNewCategory();
      }
    },
    pushToAddService() {
      this.$router.push({ name: "add_service" });
    },
    clearFilters() {
      this.isClearingFilters = true;
      this.selectedFiltersSearch = null;
      this.selectedFiltersStatuses = [];
      this.loadDataDebounced();
    },
    getQueryOptions() {
      const data = {};

      if (
        this.selectedFiltersStatuses &&
        this.selectedFiltersStatuses.length > 0
      ) {
        data.status_ids = this.selectedFiltersStatuses.join(",");
      }

      if (this.selectedFiltersSearch) {
        data.query = this.selectedFiltersSearch;
      }

      return data;
    },
    assignResponse(categories, expandStatusRestored) {
      if (
        this.selectedFiltersSearch ||
        (this.selectedFiltersStatuses &&
          this.selectedFiltersStatuses.length > 0)
      ) {
        categories = categories.filter(
          (category) => category.services && category.services.length > 0
        );
      }

      this.categories = categories;

      let totalServices = 0;
      for (let i = 0; i < categories.length; i++) {
        if (categories[i].services) {
          totalServices += categories[i].services.length;
        }
      }
      this.totalServices = totalServices;

      if (expandStatusRestored) {
        if (
          (this.selectedFiltersSearch &&
            this.selectedFiltersSearch.length > 0) ||
          (this.selectedFiltersStatuses &&
            this.selectedFiltersStatuses.length > 0)
        ) {
          if (this.totalServices <= 100) {
            this.expandAllCategories();
          } else {
            this.collapseAllCategories();
          }
        } else if (this.isClearingFilters && this.totalServices > 100) {
          this.collapseAllCategories();
        }
      }

      this.isClearingFilters = false;
    },
    loadDataDebounced: debounce(function () {
      this.loadData();
    }, 400),
    async loadData(ignoreCache) {
      this.isLoading = true;
      const expandStatusRestored = this.expandStatusRestored;

      this.saveFilters();
      const dataOptions = this.getQueryOptions();

      if (!ignoreCache) {
        const cachedTableData = await this.getCachedTableData(this.cacheId);

        if (cachedTableData) {
          const cachedOptions = cachedTableData.options;

          if (cachedOptions) {
            if (JSON.stringify(dataOptions) === JSON.stringify(cachedOptions)) {
              await this.delay(300);
              this.assignResponse(cachedTableData.data, expandStatusRestored);
              this.isLoading = false;
              return true;
            }
          }
        }
      }

      await this.searchForServices(dataOptions)
        .then((response) => {
          this.assignResponse(response, expandStatusRestored);

          this.setCacheTableData(
            {
              options: dataOptions,
              data: response,
            },
            this.cacheId
          );
        })
        .finally(() => {
          this.isLoading = false;
        });

      return true;
    },
    restoreFilters() {
      if (this.cachedTableFilters && this.cachedTableFilters[this.cacheId]) {
        const filters = this.$helpers.cloneObject(
          this.cachedTableFilters[this.cacheId]
        );
        this.selectedFiltersSearch = filters.selectedFiltersSearch;
        this.selectedFiltersStatuses = filters.selectedFiltersStatuses;
      }
    },
    restoreExpandStatus() {
      const storedCategoriesExpandStatus = getItem(
        "categoriesExpandStatusV8",
        true,
        true
      );

      if (storedCategoriesExpandStatus) {
        this.categoriesExpandStatus = storedCategoriesExpandStatus;
      } else {
        this.resetExpandStatus();
      }

      this.setAllCategoriesExpanded();
      this.expandStatusRestored = true;
    },
    resetExpandStatus() {
      const newCategoriesExpandStatus = {};

      if (this.totalServices <= 100 && this.categories && this.categories[0]) {
        newCategoriesExpandStatus[this.categories[0].id] = "e";
      }

      this.categoriesExpandStatus = newCategoriesExpandStatus;
      this.saveCategoriesExpandStatus();
    },
    saveFilters() {
      this.setCachedTableFilters({
        cacheId: this.cacheId,
        data: {
          selectedFiltersSearch: this.selectedFiltersSearch,
          selectedFiltersStatuses: this.selectedFiltersStatuses,
        },
      });
    },
    collapseAllCategories() {
      this.categoriesExpandStatus = {};
      this.saveCategoriesExpandStatus();
    },
    expandAllCategories() {
      const newCategoriesExpandStatus = {};

      this.categories.forEach((category) => {
        newCategoriesExpandStatus[category.id] = "e";
      });
      this.categoriesExpandStatus = newCategoriesExpandStatus;

      this.saveCategoriesExpandStatus();
    },
    updateCategoryExpandStatus(status) {
      const newCategoriesExpandStatus = this.categoriesExpandStatus;

      newCategoriesExpandStatus[status.categoryId] = status.isOpen ? "e" : "c";
      this.categoriesExpandStatus = newCategoriesExpandStatus;
      this.saveCategoriesExpandStatus();
    },
    saveCategoriesExpandStatus() {
      setItem(
        "categoriesExpandStatusV8",
        this.categoriesExpandStatus,
        true,
        true
      );

      this.setAllCategoriesExpanded();
    },
  },
};
</script>
