<template>
  <v-row>
    <v-col
      class="d-flex align-center justify-center justify-md-end px-0 mr-2 my-8"
    >
      <v-btn color="secondary" outlined @click="handleAddNewService(category)">
        <v-icon left>$plus-circle</v-icon>
        <span>{{ $trans("add_service") }}</span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import categoryServiceActions from "@/calendesk/mixins/categoryServiceActions";

export default {
  name: "ServiceTableButtons",
  mixins: [categoryServiceActions],
  props: {
    category: {
      type: Object,
      default: null,
      required: true,
    },
  },
};
</script>
