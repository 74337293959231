import { render, staticRenderFns } from "./ServiceRow.vue?vue&type=template&id=910b86c0&scoped=true"
import script from "./ServiceRow.vue?vue&type=script&lang=js"
export * from "./ServiceRow.vue?vue&type=script&lang=js"
import style0 from "./ServiceRow.vue?vue&type=style&index=0&id=910b86c0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "910b86c0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VChip,VCol,VIcon,VRow,VTooltip})
