<template>
  <v-row>
    <v-col cols="12" class="pa-0 ma-0">
      <v-container fluid>
        <v-row class="text-caption light--text">
          <v-col cols="0" md="1" class="d-none d-md-flex justify-center pa-0">
            <div class="table_row-line-bg-wrapper">
              <div class="table_row-line-bg"></div>
            </div>
          </v-col>
          <v-col cols="12" md="6" class="d-flex align-center">
            {{ $trans("service") }}
            <template v-if="!$vuetify.breakpoint.mdAndUp">
              / {{ $trans("duration") }} / {{ $trans("price") }}
            </template>
          </v-col>
          <v-col cols="2" md="2" class="d-none d-md-flex align-center">
            {{ $trans("duration") }}
          </v-col>
          <v-col cols="0" md="2" class="d-none d-md-flex align-center">
            {{ $trans("price") }}
          </v-col>
          <v-col
            cols="12"
            md="1"
            class="d-none d-md-flex d-md-none align-center justify-end"
          >
            {{ $trans("edit") }}
          </v-col>
        </v-row>
        <draggable
          tag="div"
          :group="{ name: 'service-group' }"
          :list="services"
          handle=".service-handle"
          :move="checkMove"
          @start="dragging = true"
          @end="onEnd('services', services)"
        >
          <template v-for="(service, index) in services">
            <service-row
              :key="service.id"
              :is-last-item="index === services.length - 1"
              :service="service"
            />
          </template>
        </draggable>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import draggable from "vuedraggable";
import categoryServiceActions from "@/calendesk/mixins/categoryServiceActions";
import ServiceRow from "@/views/dashboard/pages/Offer/Categories/components/CategoryTable/ServiceRow.vue";

export default {
  name: "ServiceTableRow",
  components: {
    ServiceRow,
    draggable,
  },
  mixins: [categoryServiceActions],
  props: {
    services: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isHovered: false,
    };
  },
};
</script>
